import React, { useState, useEffect } from "react";
import "./footer.css";
import Logo from "../../images/logo1.png";
import sendMail from "./sendEmail";
import { TextField } from "@mui/material";
import Toast from "./toastMessage"
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
const Footer = () => {
 
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

 
  const [res, setRes] = useState("")
  const [err, setError] = useState("")
  const [open, setOpen] = useState(false)
  const [disable, setDisable] = useState(false)
  useEffect(() => {
    res && setOpen(true)
    res && setDisable(false)
    err && setRes("")
}, [res, err])

useEffect(() => {
    open === false && setRes("")
}, [open])


  const onFinish = () => {
    const emailFields = {
      name,
      email,
      message,
    };

    console.log("emailFields", emailFields);
    sendMail(emailFields, setRes, setError);
  };
 
 
  const scrolltotop=()=>
  {
    scroll.scrollToTop();
  }
  return (
    <>
      {/* Audit */}

      <div className="footer" id="footer">
        <button className="  topToBottom" onClick={scrolltotop}>
          <i className="fa fa-arrow-up"></i>
        </button>

        <div className="container ">
          <div className="row mt-5 socail ">
            <div className="col-lg-6">
              <div className="text-lg-start text-center">
                <img src={Logo} height="80" alt="" />
              </div>
              <br/>
              {/* <h4 className="text-white mt-3">We're here for you</h4> */}
            </div>

            <div className="col-lg-6 ">
              <div className="row">
              {
                res ? <Toast severity="success" open={open} setOpen={setOpen} message="Email has been sent" /> :
                    err ? <Toast severity="failure" open={open} setOpen={setOpen} message="Error Occured" /> : ""
            }
                <h2 className="text-white  text-lg-start text-center  ">
                  Contact Us
                </h2>
                <div className="col-lg-6 col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                    aria-label="Your Name"
                    aria-describedby="basic-addon2"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-lg-6 col-md-6 mt-3 mt-md-0 mt-lg-0">
                  <input
                    type="email"
                    className="form-control ms-lg-2"
                    placeholder="Your Email"
                    aria-label="Your Email"
                    aria-describedby="basic-addon2"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12  mt-3 mt-md-0 mt-lg-3">
                  <input
                    type="text"
                    className="form-control mb-5  mb-lg-0 "
                    placeholder="Message"
                    aria-label="Message"
                    aria-describedby="basic-addon2"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </div>

              <div className="text-center text-lg-start mb-4 mb-lg-0">
                <button className="sendBtn   mt-lg-3  " onClick={onFinish}>
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="row  ">
            <div className="col-lg-12 text-center text-white">
              <h5 className="d-flex mt-4 justify-content-center align-item-center">
                © {new Date().getFullYear()} All Rights Reserved Powered
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
