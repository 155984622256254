import React from "react";
import "./about.css";
const About = () => {
  return (
    <div className="about my-5 " id="about">
      <div className="row py-5 align-items-center justify-content-center">
        <div className="col-lg-8  col-md-8 col-xl-6 col-xxl-5  ms-4 me-3 ">
          <h3 className="coinHeading text-center">How to Use Danacho</h3>
          <p className="mt-lg-3">
            To use Danacho, the first thing you are going to need is a wallet.
            Visit our FAQ page and get the right wallet for you. These wallets
            are available for a variety of platforms and contain everything you
            need to use Danacho immediately.
          </p>
          <h3 className="coinHeading mt-lg-5  text-center">Get Some Coins</h3>
          <p className="mt-lg-3 ">
            After you install a wallet, you need to get some Danacho. There are
            multiple ways to acquire some tokens to spend, but the easiest way
            is to use an exchange and convert your fiat money into ETH. Many
            exchanges, centralized and decentralized, list Danacho (NAC).
          </p>
          <h3 className="coinHeading mt-lg-5   text-center">It's a Currency: Use It!</h3>

          <p className="mt-lg-3">
            It's a Currency: Use It! Danacho is a currency and can be exchanged
            for goods, services and other currencies, privately and with very
            low fees. Many entities will gladly accept NAC for payments; take a
            look at our 'Merchants' page.
          </p>
        </div>
      </div>

      <div className="row py-5 align-items-center justify-content-center">
        <div className="col-lg-8  col-md-8 col-xl-6 col-xxl-5  ms-4 me-3  ">
          <h2 className="coinHeading text-center " >Find Answers to Your Questions</h2>
          <p className="mt-lg-4">
          We've heard a lot of questions and have compiled a thorough and varied FAQ. Don't worry, if your questions are not in there, you can always ask to reach out via Contact Us and we'll be happy to help!
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
