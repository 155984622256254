import React, { useEffect, useState } from "react";

import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import "./buy.css";
import Modal from "@material-ui/core/Modal";
import ConnectModal from "../../modal/connectModal";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import SellModal from "../../modal/sellModal";
import EndTimer from "../../endTime";
import {
  maxContributionAsync,
  minimumContributionAsync,
  rateAsync,
  buyTokensAsync,
} from "../../redux/slices/web3ConnectSlice";
const Buy = () => {
  const dispatch = useAppDispatch();

  const { returnRate, minValue, maxValue, web3, web3LoadingErrorMessage } =
    useAppSelector((state) => state.web3Connect);
  const [openConnect, setOpenConnect] = React.useState(false);
  const [showToast, setShowToast] = useState(false);

  const [etherAmount, setEtheAmount] = useState(" ");
  const [rate, setRate] = useState("");

  const handleEthAmountAndRate = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setEtheAmount(e.target.value);
    if (web3) {
      dispatch(rateAsync());
    }
  };

  console.log("etherAmount", etherAmount);
  const onSubmit = async () => {
    try {
      const userAmount = Number(etherAmount) * 10e17;
      // etherAmount * 10e17;
      //pass amount in parameter of this function
      dispatch(
        buyTokensAsync({
          amount: userAmount,
        })
      );
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleConnectOpen = () => {
    setOpenConnect(true);
  };

  const handleConnectClose = () => {
    setOpenConnect(false);
  };

  React.useEffect(() => {
    if (web3LoadingErrorMessage) {
      setShowToast(true);
    }
    setTimeout(() => {
      setShowToast(false);
      console.log("running");
    }, 6000);
  }, [web3LoadingErrorMessage]);
  console.log("minValueminValue", minValue);
  console.log("maxValuemaxValue", maxValue);

  console.log("returnRate", returnRate, minValue, rate);

  React.useEffect(() => {
    dispatch(maxContributionAsync());
    dispatch(minimumContributionAsync());
    dispatch(rateAsync());
    setRate(returnRate);
  }, [web3, returnRate]);

  return (
    <div className="bnb my-5 my-lg-0" id="buy">
      {showToast ? (
        <div>
          <Snackbar open={showToast} autoHideDuration={3000}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Please Connect to Ethereum Networks
            </Alert>
          </Snackbar>
        </div>
      ) : null}
      <div className="container">
        <div className="row   align-items-center justify-content-center">
          <div className="col-lg-8">
            <div className="form">
              {/* <h3 className="text-center">1 ETH = {returnRate} Danacho</h3> */}
              <h3 className="text-center">Buy Danacho (NAC)</h3>

              {rate ? (
                <>
                  {Number(etherAmount) >= 0.00021 ? (
                    <h5 className="text-center" style={{ color: "black" }}>
                      {rate
                        ? (
                            (Math.abs(Number(etherAmount)) * 10e17) /
                            (10e17 / Number(rate))
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : 10}{" "}
                      Danacho{" "}
                    </h5>
                  ) : (
                    <h3 className="text-center">
                      Minimum {210000000000000 / 10e17} ETH is required
                    </h3>
                  )}
                </>
              ) : (
                ""
              )}

              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6   mt-5">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control  bnbInput"
                      id="floatingInput"
                      placeholder="BNB"
                      value={etherAmount}
                      onChange={(e) => handleEthAmountAndRate(e)}
                    />
                    <label>Enter ETH</label>
                  </div>
                </div>
              </div>
              <div className="text-center">
                {web3 ? (
                  <div>
                    {Number(etherAmount) > minValue / 10e17 &&
                    Number(etherAmount) < maxValue / 10e17 ? (
                      <button className="buyBtn" onClick={onSubmit}>
                        Buy Now
                      </button>
                    ) : (
                      <button className="buyBtn-disabled" disabled>
                        Buy Now
                      </button>
                    )}
                  </div>
                ) : (
                  <div>
                    <button className="buyBtn" onClick={handleConnectOpen}>
                      Connect Wallet{" "}
                    </button>
                    <Modal
                      open={openConnect}
                      onClose={handleConnectClose}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                    >
                      <ConnectModal setOpenConnect={setOpenConnect} />
                    </Modal>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buy;
