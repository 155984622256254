import React, { useState, useEffect } from "react";
import "./Navbar.css";
import logo from "../../images/logo1.png";
const Navbar = () => {
  const [navbarClose, setNavbarClose] = useState(false);
  console.log(navbarClose);
  const handleCollapse = () => {
    setNavbarClose(!navbarClose);
  };
  useEffect(() => {
    setTimeout(() => {
      setNavbarClose(false);
    }, 1000);
  }, [navbarClose])
 
  return (
    <>
      <nav className="navbar navbar-expand-lg  ">
        <div className="container">
          <a className=" ms-lg-4 navbar-brand" href="#">
            <img src={logo} width={50} height={50} alt="" />
          </a>
          <button
               className="navbar-toggler customToggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              {" "}
              <i className="fas fa-bars fa-2x"></i>
            </span>
          </button>
          <div
           className={
            navbarClose
              ? "collapse navbar-collapse show"
              : "collapse navbar-collapse  "
          }
            id="navbarText"
          >
            <ul className="navbar-nav me-lg-auto   ms-lg-auto  mb-2 mb-lg-0">
              <li className="nav-item  me-1 "  onClick={handleCollapse}>
                <a
                  href="#buy"
                  className="nav-link    "
                 
                >
                  Buy
                </a>
              </li>

              <li className="nav-item">
                <a
                  href="#about"
                  className="nav-link  "
                  onClick={handleCollapse}
                >
                  About
                </a>
              </li>
              <li className="nav-item me-1  ">
                <a href="#faq" className="nav-link " onClick={handleCollapse}>
                  FAQ
                </a>
              </li>
              <li className="nav-item  me-1 ">
                <a
                  href="#footer"
                  className="nav-link    "
                  onClick={handleCollapse}
                >
                  Contact
                </a>
              </li>
            </ul>

            <ul className="d-flex justify-content-center socialIcon me-3">
              <a className="nav-item" href="https://twitter.com/Danacho14" target="_blank">
                <i className="fab fa-twitter  me-1 brandIcon"></i>
              </a>
              <a className="nav-item me-1" href="https://www.instagram.com/_danacho/" target="_blank">
                <i className="fab fa-instagram  brandIcon "></i>
              </a>
              {/* <li className="nav-item  me-1 ">
                <i className="fab fa-discord  brandIcon"></i>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
