import React  from "react";
import "./herosection.css";
import Startimer from "../startTime";
import EndTimer from "../../endTime";
const HeroSection = () => {
   

  return (
    <>
    
      <section className="heroSection bgoverlay   ">
        <div className="container  ">
          <div className="row  heroRow"  >
            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center    ">
            <h1 className="mb-3 ">Welcome to   Danacho</h1>

              <h3 className="mb-3 mt-3 text-white ">Presale Start Time</h3>

              <Startimer/>
              <h3 className="mb-3  text-white " >Presale End Time</h3>

              <EndTimer />
              <div className="socailDiv">
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
