 
import { Navbar,Buy, Footer, HeroSection, Faq, About } from "./components/exportcomponent";
import './App.css';
function App() {
  return (
    <div  className="body">
      <Navbar />
      <HeroSection />
      <Buy/>
      <About />
      <Faq />
      <Footer/>
    </div>
  );
}

export default App;
