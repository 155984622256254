import React from "react";
import "./faq.css";
import { faqs } from "./faqData";
import FaqComponent from "./FaqComponent";
const Faq = () => {
  return (
    <>
      <section className="faqSection   " id="faq">
        <div className="container text-black  ">
          <div className="row  align-items-center justify-content-center">
            <div className="col-lg-8 ">
              <h3 className=" text-center  ">Frequently Asked Questions</h3>

              <div
                className="accordion accordion-flush mt-5  text-black "
                id="accordionExample"
              >
                {faqs?.map((item) => (
                  <FaqComponent item={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
