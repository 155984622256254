import * as emailjs from "emailjs-com";

const SERVICE_ID = "service_ocxtf57";
const TEMPLATE_ID = "template_fqil3tf";
const USER_ID = "user_EQ467k39vuma4KfPjDovg";


const sendMail: any = (emailFields: any, setRes: any, setError: any) => {

  const templateParams = {
    to_name: "Danacho",
    to_email: "",
    message: emailFields.message,
    from_name: emailFields.name,
    reply_to: emailFields.email
  }

  emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID).then(
    function (response) {
      setRes(response.status)
    },
    function (err) {
      setError(err)
    }
  );
}


export default sendMail;
