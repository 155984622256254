import React from "react";
import "./faq.css";
type Props = {
  item: any;
};

const FaqComponent: React.FC<Props> = ({ item }) => {
  return (
  
      <div className="accordion-item">
        <h2 className="accordion-header" id={"heading" + item.key}>
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={"#collapse" + item.key}
            aria-expanded="false"
            aria-controls={"collapse" + item.key}
          >
            {item.title}
          </button>
        </h2>
        <div
          id={"collapse" + item.key}
          className="accordion-collapse collapse"
          aria-labelledby={"heading" + item.key}
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            {item.desc}
            {item.key === 4 && <p className="mt-3">{item?.desc1}</p>}
          </div>
        </div>
      </div>
 
  );
};

export default FaqComponent;
